import React from "react";
import { graphql } from "gatsby";
// import tw from "twin.macro";
import styled from "@emotion/styled";
// import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";

import CallToAction from "../components/Repeating/CTA";

import HarborViewLifestyle from "../components/Repeating/HarborViewLifestyle";

import AmenitiesSection1 from "../components/Amenities/AmenitiesSection1";
import AmenitiesSection2 from "../components/Amenities/AmenitiesSection2";
// import AmenitiesSection3 from "../components/Amenities/AmenitiesSection3";
const StyledContent = styled.div``;
const Page = (props) => {
  const { data } = props;

  // if (errors) {
  //   return (
  //     <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
  //       <GraphQLErrorList errors={errors} />
  //     </Layout>
  //   );
  // }
  return (
    <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
      <SearchEngineOptimization
        title="Amenities | MesaView Senior Assisted Living"
        description="At MesaView, we provide a wide variety of amenities designed to deliver compassionate care and enrich the lives of each of our residents."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <StyledContent>
        <div className="hidden md:block">
          <AmenitiesSection1
            sectionTitle={data.sanityAmenities.title}
            sectionDesc={data.sanityAmenities.description}
          />
        </div>
        <div className="block bg-primary-700 md:hidden">
          <AmenitiesSection1
            sectionTitle={data.sanityAmenities.title}
            sectionDesc={data.sanityAmenities.description}
            title1={data.sanityAmenities.subtitle1}
            heroImage={data.sanityAmenities.backgroundimage.asset.url}
          />
        </div>
        {/* bullet list  */}
        <AmenitiesSection2
          sectionTitle={data.sanityAmenities.secondsectiontitle}
          sectionDesc={data.sanityAmenities.description}
          title1={data.sanityAmenities.secondsectiontitle}
          arrary1={data.sanityAmenities.secondsectioncontentarray}
        />
        {/* accommadations */}
        {/* <AmenitiesSection3
          sectionTitle={data.sanityAmenities.title}
          sectionDesc={data.sanityAmenities.description}
        /> */}
      </StyledContent>
      <HarborViewLifestyle hide={4} />
      <CallToAction phonenumber={data.contactInfo.phonenumber} />
    </Layout>
  );
};

export const query = graphql`
  {
    contactInfo: sanityContactinfo {
      title
      description
      phonenumber
      email
      address
      license
      bookingurl
    }
    siteSettings: sanitySiteSettings {
      title
      description
      author
      headerlogo {
        asset {
          gatsbyImageData
        }
      }
      footerlogo {
        asset {
          gatsbyImageData
        }
      }
      facebookurl
      twitterurl
      privacypolicy
    }

    sanityAmenities {
      title
      description
      thirdsectiontitle
      thirdsectioncontent
      secondsectiontitle
      secondsectioncontent
      secondsectioncontentarray
      subtitle1
      subtitle2
      subtitle3
      _rawDescription1
      _rawDescription2
      _rawDescription3
      backgroundimage {
        asset {
          url
        }
      }
    }
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Facebook.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Twitter.jpg" }
    ) {
      publicURL
    }
    posts: allSanityBlogs(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          featuredImage {
            asset {
              gatsbyImageData
            }
          }
          categories {
            _id
            title
          }
          title
          slug {
            current
          }
          _rawBody(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  }
`;

export default Page;
